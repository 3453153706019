@import "bootstrap/variables.less";
@import "bootstrap/mixins.less";

.login-box {
    margin-left: -81px;

    .vertical-wrapper {
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    table {
        display: inline-block;
        vertical-align: middle;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        margin-top: 10px;
    }
    td {
        background-color: #fff;
        opacity: 0.8;
        filter: alpha(opacity=80);
        color: @gray;
        border: 2px solid transparent;
        text-align: left;
    }
}

.login-alert {
    position: absolute;
    top: 30px;
    width: 470px;
    line-height: 25px;
    color: #111;
    border: 1px dashed #111;
    padding: 5px 0px 2px 10px;
}


.login-logo {
    padding: 15px;
    vertical-align: top;
}

.login-content {
    position: relative;
    width: 100%;
    padding: 70px 70px;

    .login-top-message {
        margin-top: -25px;
        margin-bottom: 25px;
    }

    h1 {
        font-family: @roboto;
        font-weight: normal;
        font-size: 32px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    p {
        font-size: 18px;
        margin-bottom: 40px;
    }

    .radio-block {
        font-size: inherit;
        margin: 15px 0 0 0;
        padding: 0;
    }
    .radio-block input[type=radio] {
        margin-left: 10px;
        margin-right: 0;
    }

    small {
        float: right;

        a {
            color: inherit;
        }
    }

    input[type=text], input[type=email], input[type=password], select {
        display: block;
        padding: 5px 15px;
        margin: 8px 0;
        background-color: #b9bebf;
        border: 0;
        border-radius: 0;
        color: #000;

        &::-webkit-input-placeholder { color: #777; }
        &:-moz-placeholder { color: #777; }
        &::-moz-placeholder { color: #777; }
        &:-ms-input-placeholder { color: #777; }

        outline-color: #b9bebf;
        outline-offset: 0;
        outline-style: solid;
        outline-width: 1px;

        &:focus, &:active {
            outline-color: lighten(@gray, 20%);
            outline-offset: 0;
            outline-style: solid;
            outline-width: 1px;
        }
    }
    button {
        display: block;
        height: 35px;
        width: 498px;
        padding: 5px 15px;
        margin-top: 60px;
        background-color: @gray;
        border: 0;
        font-family: @roboto;
        font-size: 18px;
        color: #fff;
        cursor: pointer;
        text-transform: uppercase;

        outline-color: @gray;
        outline-offset: 0;
        outline-style: solid;
        outline-width: 1px;

        &:focus, &:hover {
            outline-color: #000;
            outline-offset: 0;
            outline-style: solid;
            outline-width: 1px;
            background-color: @gray-dark;
        }
        &:active {
            background-color: @gray-darker;
        }
    }

    input[type=checkbox], input[type=radio] {
        margin-right: 10px;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            margin-bottom: 10px;

            input[type=text] {
                display: inline;
                background-color: @gray;
                outline: 0;
                border: 0;
                border-bottom: 1px dashed @gray-lighter;
                color: #eee;
                font-style: italic;
                font-weight: normal;
                font-family: @roboto;
                padding: 0 5px;
                margin: -2px 0 0 5px;
                width: 550px;
            }
        }
    }

    label {
        font-weight: normal;
        cursor: pointer;
        margin-bottom: 0;
    }

    .modal-goals {
        p {
            margin-bottom: 25px;
        }
        button {
            display: inline;
            margin-right: 30px;
            width: 250px;
            background-color: @green-manageris;
            color: @gray;
            font-weight: bold;

            outline-color: @green-manageris;

            &:focus, &:hover {
                outline-color: @green-manageris;
                background-color: darken(@green-manageris, 4%);
                color: darken(@gray, 4%);
            }
            &:active {
                background-color: darken(@green-manageris, 8%);
                color: darken(@gray, 8%);
            }
       }
    }
}

.login-toolbox-container {
    width: 638px;
    border: 1px solid #ddd;
    margin: 150px auto 60px;

    .error-message {
        position: absolute;
        top: 30px;
        color: #d2322d;
    }

    &.login-with-logo {
        margin-top: 210px;

        .login-big-logo {
            margin-top: -150px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 55px;
            height: 140px;
        }
    }
}

.login-toolbox {
    margin: 0;
    color: #3b3b3d;

    p {
        width: 500px;
        margin-bottom: 20px;
    }

    input[type=text], input[type=email], input[type=password] {
        height: 35px;
        width: 498px;
    }

    small {
        float: none;
        display: block;
        width: 498px;
        font-size: 14px;
        text-align: right;

        a {
            text-decoration: underline;

            &:hover {
                color: #000;
            }
        }
    }

    button {
        margin-top: 40px;
    }
}

.login-toolbox-redirection-container {
    background-color: #f2f2f2;
}
.login-toolbox-redirection {
    width: 638px;
    margin: 0 auto;
    padding: 60px 0 100px;
}

.form-language  {
    display: inline;
    line-height:43px;

    input[type=submit]{
        border: none;
        outline: none;
        background:none;
        display: inline;
        line-height:43px;
    }

    input[type=submit]:hover{
        text-decoration: underline;
    }
}

.form-language+.form-language:before{
    content: "|";
}

.front-checkbox-input {
    display: none;
}

.front-checkbox {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid #3c3c3e;
    cursor: pointer;
}

.front-checkbox.checked {
    background-color: #3c3c3e;
    color: #fff;
}

.front-checkbox.checked:after {
    display: block;
    content: '✓';
    text-align: center;
}

.management_logo_area .big_logo {
    max-height: 55px;
    margin-right: 20px;
}

.login-saml-btn {
    margin-top: 0 !important;
    height: 50px !important;
    text-align: center;
    padding: 15px !important;
    color: #fff !important;
    outline: none !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: #000 !important;
}

.login-toolbox-container {
    max-width: 100%;
}

.login-content * {
    max-width: 100%;
}

@media screen and (max-width : 800px) {
    .login-content {
        padding: 20px;
    }
}

@media screen and (max-width : 639px) {
    .front-checkbox {
        display: none;
    }

    .front-checkbox-input {
        display: initial;
        vertical-align: top;
    }

}

.login-bottom-right-link {
    text-align: right;
    padding: 10px;
}

.login-bottom-right-link a {
    color: #000 !important;
    text-decoration: underline;
}

.login-oauth-btn {
    text-align: center;

    a.login-saml-btn {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}

